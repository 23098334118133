import React from "react";
import { graphql } from "gatsby"

import Metadata from '../components/Metadata';
import BlogItem from "../components/BlogItem";
import PageLayout from "../layouts/Page";

import './Tag.scss';

export const query = graphql`
  query SanityAuthorQuery($slug: String!) {
    allSanityBlogPost(
      filter: {authors: {elemMatch: {slug: {current: {eq: $slug}}}}}
      sort: { fields: date, order: DESC }
      ) {
      nodes {
        title
        date(formatString: "MMMM DD, YYYY")
        slug {
          current
        }
        authors {
          name
          image {
            ...ImageWithPreview
          }
        }
        _rawText(resolveReferences:{maxDepth:1000})
        textMarkdown
        excerpt
        featuredImage {
          altText
          image {
            ...ImageWithPreview
          }
        }
      }
    }
    author: sanityPeople(slug: {current: {eq: $slug}}) {
      name
    }
  }
`;

const HeaderString = ({posts, tag}) => {
  const totalPosts = posts.length;
  return `${totalPosts} post${totalPosts === 1 ? "" : "s"} by ${tag}`;
}

const BlogAuthor = props => {
  const authorName = props.data.author.name;
  const blogPosts = props.data.allSanityBlogPost.nodes;

  return (
    <PageLayout>
      <Metadata title={`Okteto Blog - ${authorName}`} />
      <div className="Tag">
        <h1><HeaderString posts={blogPosts} tag={authorName} /></h1>
        <div className="TagGrid">
          {blogPosts.map((post, index) => {
            return <BlogItem post={post} key={index} />
          })}
        </div>
      </div>
    </PageLayout>
  );
}

export default BlogAuthor;